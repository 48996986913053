/* src/Components/LiveKit/AudioRoom.css */
/* AudioRoom.css */
:root {
  /* LiveKit theme overrides to match Chakra UI */
  --lk-accent-fg: var(--chakra-colors-white);
  --lk-accent-bg: var(--chakra-colors-blue-500);
  --lk-accent-hover: var(--chakra-colors-blue-600);
  
  /* Voice assistant configuration */
  --lk-va-bar-width: 72px;
  --lk-control-bar-height: unset;
}

/* Voice assistant states using LiveKit data attributes */
[data-lk-agent-state="listening"] .lk-audio-bar {
  background-color: var(--chakra-colors-blue-500);
}

[data-lk-agent-state="thinking"] .lk-audio-bar {
  background-color: var(--chakra-colors-orange-500);
}

[data-lk-agent-state="speaking"] .lk-audio-bar {
  background-color: var(--chakra-colors-green-500);
}

[data-lk-agent-state="initializing"] .lk-audio-bar,
[data-lk-agent-state="connecting"] .lk-audio-bar {
  background-color: var(--chakra-colors-gray-500);
}

/* Base visualizer styles */
.agent-visualizer > .lk-audio-bar {
  width: var(--lk-va-bar-width);
  transition: background-color 0.3s ease;
}

/* LiveKit control bar customization */
[data-lk-theme="default"] {
  --lk-button-background: var(--chakra-colors-blue-500);
  --lk-button-background-hover: var(--chakra-colors-blue-600);
  --lk-button-foreground: var(--chakra-colors-white);
}
