/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Minimal CSS Reset */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: 100%;
}
